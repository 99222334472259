import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { CookieKeys } from 'types/cookies';
import { Option } from 'types/options';
import ListCollection from 'types/redux/ListCollection';

import Product, {
    Gender,
    Genders,
} from 'models/Product';
import ProductCategory from 'models/ProductCategory';

import { PRODUCTS } from 'consts/sections';
import { getOptionValue } from 'utils/option';
import { getProvider } from 'services/Cookies';

import { list as listProducts } from 'requests/products';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonElements } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import MobileSlider from 'components/layout/MobileSlider';
import SpinnerNew from 'components/layout/SpinnerNew';
import TabNavigation from 'components/layout/TabNavigation/component';
import { TabVariants } from 'components/layout/TabNavigation/types';
import ProductCategorySelector from 'components/modules/public/layout/ProductCategorySelector';
import OfferBoxProduct from 'components/modules/public/products/OfferBoxProduct';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionPackagesVariantWithTabs: FunctionComponent<Props> = ({
    translations,
    productsList,
    locationId,
    sectionSettings,
}) => {
    const router = useRouter();
    const isMounted = useRef<boolean>(false);
    const usedGender = getProvider().get(Gender.gender);
    const [productListState, setProductListState] = useState<ListCollection<Product>>(productsList);
    const [selectedCategory, setSelectedCategory] = useState<Option<ProductCategory>>();
    const [selectedGender, setSelectedGender]: [Option<Genders>, Function] = useState(sectionSettings?.filters?.gender || usedGender || Genders.Female);

    const localeContext = useContext(LocaleContext);
    const sectionPackagesTranslations = localeContext?.translations?.blockSectionPackages;


    const setCookieValues = () => {
        getProvider().set(CookieKeys.Gender, getOptionValue(selectedGender));
        router.replace(router.asPath, undefined, { shallow: true });
    };

    useEffect(() => {
        if (isMounted.current) {
            setCookieValues();
        } else {
            isMounted.current = true;
        }
    }, [selectedGender, selectedCategory]);

    useEffect(() => {
        let cancel = false;

        if (!productsList) {
            listProducts({
                page: 1,
                perPage: 3,
                ...(sectionSettings?.filters || {}),
                locationId: sectionSettings?.filters?.locationId?.value || null,
                bodyPartGroupId: sectionSettings?.filters?.bodyPartGroupId?.value || null,
                gender: getOptionValue(selectedGender),
                categoryId: getOptionValue(selectedCategory)?.id,
            })
                .then(response => {
                    if (cancel) return;
                    setProductListState(response);
                });
        }

        return () => {
            cancel = true;
        };
    }, [productsList, selectedGender, selectedCategory]);

    return (
        <StyledComponent className="public-section-packages-variant-with-tabs">
            <LayoutContainer>
                {sectionSettings?.filters?.isCategorySwitchEnabled && (
                    <div className="options-selector">
                        <ProductCategorySelector
                            selectedOption={selectedCategory}
                            onSelect={(nextCategory) => setSelectedCategory(nextCategory)}
                        />
                    </div>
                )}
                {translations?.headline && (
                    <div
                        className="headline"
                        dangerouslySetInnerHTML={{ __html: translations?.headline }}
                    />
                )}
                <TabNavigation
                    activeTabKey={getOptionValue(selectedGender)}
                    onTabChange={(nextActiveTab) => setSelectedGender(nextActiveTab as Genders)}
                    tabsVariant={TabVariants.Gradient}
                    tabs={sectionPackagesTranslations?.tabs.map(tab => ({
                        ...tab,
                        children: (
                            <div className="offer-boxes">
                                {productListState?.isLoading && (
                                    <SpinnerNew />
                                )}
                                <div className="offer-boxes-desktop">
                                    {productListState?.elements.map(element => (
                                        <OfferBoxProduct
                                            {...sectionPackagesTranslations?.copy}
                                            key={element?.id}
                                            product={element}
                                            section={PRODUCTS}
                                            onCreateCartOffer={() => null}
                                            isBuyNowDisabled={sectionSettings?.filters?.isBuyNowDisabled}
                                            isAddToCartDisabled={sectionSettings?.filters?.isAddToCartDisabled}
                                            locationId={locationId}
                                        />
                                    ))}
                                </div>
                                <MobileSlider sliderProps={{
                                    centerMode: false,
                                }}
                                >
                                    {productListState?.elements.map(element => (
                                        <OfferBoxProduct
                                            {...sectionPackagesTranslations?.copy}
                                            key={element?.id}
                                            product={element}
                                            section={PRODUCTS}
                                            onCreateCartOffer={() => null}
                                            isBuyNowDisabled={sectionSettings?.filters?.isBuyNowDisabled}
                                            isAddToCartDisabled={sectionSettings?.filters?.isAddToCartDisabled}
                                            locationId={locationId}
                                        />
                                    ))}
                                </MobileSlider>
                            </div>
                        ),
                    }))}
                />
                {translations?.buttonLabel && (
                    <div className="control">
                        <Button
                            element={ButtonElements.Anchor}
                            href={translations?.buttonLink}
                        >
                            {translations?.buttonLabel}
                        </Button>
                    </div>
                )}
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicSectionPackagesVariantWithTabs;
